<template>
  <div class="wrapper">
    <h1 class="title">{{ organization }}</h1>
    <div>
      <button class="btn" @click="changeTheme">
        <component class="btn-icon" :is="icon" />
      </button>
      <button v-if="isNotPermDen" class="btn" @click="exit">
        <ArrowRightOnRectangleIcon class="btn-icon" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { MoonIcon, ArrowRightOnRectangleIcon } from "@heroicons/vue/24/outline";
import { SunIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref } from "vue";
// import { VUE_APP_LOGOUT } from "@/store/utils/constants";
import { useStore } from "vuex";
import { useRouter } from "vue-router/dist/vue-router";

const store = useStore();
const router = useRouter();
const icon = ref(SunIcon);
const organization = computed(() => store.getters.organization);

onMounted(async () => {
  await store.dispatch("getOrganization");
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
    icon.value = MoonIcon;
  } else {
    document.documentElement.classList.remove("dark");
    icon.value = SunIcon;
  }
});

const changeTheme = () => {
  if (icon.value === SunIcon) {
    icon.value = MoonIcon;
    localStorage.theme = "dark";
    document.documentElement.classList.add("dark");
  } else {
    icon.value = SunIcon;
    localStorage.theme = "light";
    document.documentElement.classList.remove("dark");
  }
};

const isNotPermDen = computed(() => {
  // console.log(router.currentRoute.value.name !== "permission-denied");
  return router.currentRoute.value.name !== "permission-denied";
});

const exit = () => {
  // console.log("я тут exit");
  store.commit("SET_DIALOG_VISIBLE", true);
  // await store.dispatch("closeSession");
  // window.location.href = `${VUE_APP_LOGOUT}?goto=https://plte-portal.mts-corp.ru`;
};
</script>

<style scoped>
.wrapper {
  @apply flex justify-between items-center w-full bg-slate-300 dark:bg-slate-700 mb-5 max-h-14;
}

.title {
  @apply text-xl p-5;
}

.btn {
  @apply rounded-full transition ease-in-out delay-150 hover:bg-amber-50 hover:dark:bg-slate-800 w-7 h-7 mx-5 m-1;
}

.btn-icon {
  @apply text-slate-700 dark:text-blue-200 p-1;
}
</style>
