import axios from "axios";

const authModule = {
  state: {
    userConfig: false,
    access: false,
  },
  getters: {
    userConfig: (state) => {
      return state.userConfig;
    },
    access: (state) => {
      return state.access;
    },
  },
  mutations: {
    SET_ACCESS: (state, access) => {
      state.access = access;
    },
  },
  actions: {
    checkSession: async ({ commit }) => {
      // Проверка наличия локальной сессии и SSO сессии

      // Функция для проверки валидность SSO-сессии
      const [sso_session, guid] = await axios
        .get("https://login.mts.ru/amserver/oauth2/sso/validate", {
          withCredentials: true,
        })
        .then((response) => {
          // console.log("Проверка наличия SSO сессии:", response.data);
          const sso_valid = response.data.isSessionCookieValid;
          const guid = sso_valid ? response.data.session_cookie.guid : null;
          // console.log("sso_valid", sso_valid);
          // console.log("guid", guid);
          return [sso_valid, guid];
        })
        .catch(() => {
          // console.log(err.toJSON());
          return [false, null];
        });
      debugger;
      // Функция для роверки наличия локальной сессии и ее соттветствие SSO-сессии
      const local_session = await axios
        .post(
          "/auth/check",
          {
            guid: guid,
            sso_session: sso_session,
          },
          { withCredentials: true }
        )
        .then((response) => {
          // console.log("Проверка наличия локальной сессии:", response.data);
          return response.data;
        })
        .catch(() => {
          // console.log(err.toJSON());
          return false;
        });
      // console.log("guid", guid);
      // console.log("local_session", local_session);
      // console.log("sso_session", sso_session);
      commit("SET_ACCESS", local_session);
      return local_session;
    },
    closeSession: async () => {
      // Закрытие сессии
      await axios.get("/auth/exit", {
        withCredentials: true,
      });
    },
  },
};

export default authModule;
