<template>
  <div dir="rtl" class="sidebar-wrapper">
    <slot name="sidebar-content"></slot>
  </div>
</template>

<script setup></script>

<style scoped>
.sidebar-wrapper {
  @apply fixed left-0 top-0 z-10 w-1/5 overflow-y-auto h-screen bg-slate-200 dark:bg-slate-600;
}

.sidebar-wrapper::-webkit-scrollbar {
  @apply w-3.5 bg-slate-50 dark:bg-neutral-400;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
  @apply w-2 bg-slate-400 dark:bg-neutral-700;
}
</style>
