<template>
  <div class="table-wrapper">
    <table class="table-content">
      <tbody class="table-body">
        <tr>
          <td class="table-td td-title">№ инцидента</td>
          <td class="table-td" v-html="ttInfo.REQUEST_ID"></td>
        </tr>
        <tr>
          <td class="table-td td-title">Статус</td>
          <td class="table-td">{{ ttInfo.STATUS_TXT }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Время события</td>
          <td class="table-td">{{ ttInfo.EVENTTIME_DT }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Группа исполнителя</td>
          <td class="table-td">{{ ttInfo.GNAME2 }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Описание</td>
          <td class="table-td" style="word-break: break-word">
            {{ ttInfo.SHORTDESCRIPTION }}
          </td>
        </tr>
        <tr>
          <td class="table-td td-title">Объект сети</td>
          <td class="table-td">{{ ttInfo.NENAME }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Адресс объекта сети</td>
          <td class="table-td">{{ ttInfo.NEADDRESS }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Описание влияния</td>
          <td class="table-td">{{ ttInfo.SERVICEIMPACTCLS }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Причина классификатор</td>
          <td class="table-td">{{ ttInfo.CLASSIFICATORCAUSE }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Решение</td>
          <td class="table-td">{{ ttInfo.SOLUTION }}</td>
        </tr>
        <tr>
          <td class="table-td td-title">Время решения</td>
          <td class="table-td">{{ ttInfo.RESOLUTION_TIME_DT }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

const ttInfo = computed(() => store.getters.ttInfo);
</script>

<style scoped>
.table-wrapper {
  @apply flex flex-col items-center;
}

.table-content {
  @apply border-collapse border border-slate-100 bg-slate-300 dark:bg-slate-600 max-w-2xl;
}
/*.table-body {*/
/*  @apply p-5;*/
/*}*/

.table-td {
  @apply p-2 border border-slate-200 dark:border-slate-500;
}

.td-title {
  @apply p-2 border bg-slate-400 dark:bg-slate-700;
}
</style>
