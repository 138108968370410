<template>
  <CustomSidebar>
    <template v-slot:sidebar-content>
      <SidebarBlock :header="`${currentEl.element}: Активные ТТ`">
        <template v-slot:block>
          <div dir="ltr" class="block-content" v-if="currentEl.ttList.length">
            <IncidentCard
              v-for="tt in currentEl.ttList"
              :key="tt.requestId"
              :class="activeColor(tt.requestId)"
              :incident="tt"
              @click="getFullTTData(tt.requestId)"
            />
          </div>
          <div dir="ltr" class="no-data" v-else>Нет инцидентов</div>
        </template>
      </SidebarBlock>
    </template>
  </CustomSidebar>
</template>

<script setup>
import CustomSidebar from "@/components/CustomSidebar";
import SidebarBlock from "@/components/SidebarBlock";
import { useStore } from "vuex";
import { computed } from "vue";
import IncidentCard from "@/components/IncidentCard";
import { useRouter } from "vue-router/dist/vue-router";

const store = useStore();
const router = useRouter();
const currentEl = computed(() => store.getters.currentEl);
const currentTT = computed(() => store.getters.currentTT);

const getFullTTData = async (requestId) => {
  // console.log(requestId);
  await store.dispatch("getTTInfo", requestId);
  await router.push(`/ne/${router.currentRoute.value.params.ne}/incident`);
};

const activeColor = (requestId) => {
  // console.log(router.currentRoute.value.params);
  return requestId === currentTT.value
    ? "bg-slate-500/70 dark:bg-slate-950/70 border-r-8 border-r-slate-500 dark:border-r-slate-200"
    : "bg-slate-400/70 dark:bg-slate-800";
};
</script>

<style scoped>
.block-content {
  @apply flex flex-col gap-5 p-4;
}

.no-data {
  @apply w-full text-center;
}
</style>
