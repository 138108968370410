<template>
  <HeaderComp v-if="access" />
  <Transition name="slide">
    <IncidentsPanel v-if="sidebarVisible" />
  </Transition>
  <Transition name="fade" mode="out-in">
    <BackButton
      v-if="backButtonVisible"
      :class="{
        'active-sidebar-back-btn': sidebarVisible,
        'm-2': !sidebarVisible,
      }"
      @click="goBack"
    />
  </Transition>
  <router-view
    v-if="access || router.currentRoute.value.name === 'permission-denied'"
    v-slot="{ Component }"
    :class="{ 'active-sidebar': sidebarVisible }"
  >
    <Transition name="fade" mode="out-in">
      <component :is="Component" />
    </Transition>
  </router-view>
  <ConfirmDialog class="confirm" v-if="dialogVisible" />
</template>

<script setup>
import HeaderComp from "@/components/HeaderComp";
import IncidentsPanel from "@/components/IncidentsPanel";
import BackButton from "@/components/BackButton";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router/dist/vue-router";
import ConfirmDialog from "@/components/ConfirmDialog";

const store = useStore();
const router = useRouter();
const access = computed(() => store.getters.access);
const sidebarVisible = computed(() => store.getters.sidebarVisible);
const dialogVisible = computed(() => store.getters.dialogVisible);
const backButtonVisible = computed(() => router.currentRoute.value.params.ne);

const goBack = () => {
  if (router.currentRoute.value.name === "incident-view") {
    router.push(`/ne/${router.currentRoute.value.params.ne}`);
  } else {
    router.push(`/`);
  }
};
</script>

<style>
#app {
  @apply min-h-screen w-screen sm:my-0 md:m-auto justify-center;
}
.confirm {
  @apply absolute left-0 top-0 flex justify-center;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
  width: 0;
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /*transform: translateX(-100%);*/
}

.active-sidebar {
  /*width: calc(100% - 20em);*/
  margin-left: 20%;
}

.active-sidebar-back-btn {
  margin-left: calc(20% + 0.5rem);
}
</style>
