<template>
  <div class="wrapper">
    <div class="info-card">
      <h1 class="title">Доступ запрещен</h1>
      <p class="description">
        У вас нет доступа к приложению, возможно вы использовали неверный номер
        телефона для авторизации
      </p>
      <button class="btn-back" @click="goToAuth">
        Вернутья к форме авторизации
      </button>
    </div>
  </div>
</template>

<script setup>
import { VUE_APP_HOST } from "@/store/utils/constants";

const goToAuth = () => {
  window.location.href = `${VUE_APP_HOST}/auth/authorize?goto=${VUE_APP_HOST}`;
};
</script>

<style scoped>
.wrapper {
  @apply flex flex-wrap flex-col justify-center items-center;
}
.info-card {
  @apply flex flex-wrap flex-col justify-center items-center gap-20;
}
.title {
  @apply text-3xl p-5;
}
.description {
  @apply block text-xl;
}

.btn-back {
  @apply p-3 bg-blue-200 dark:bg-blue-500 hover:bg-blue-300 hover:dark:bg-blue-600 rounded;
}
</style>
