<template>
  <div class="home">
    <div class="home-wrapper">
      <div class="home-content" v-if="totalInfo">
        <div class="cores-list">
          <DataCard
            v-for="(cardInfo, cardTitle) in totalInfoCores"
            :key="cardTitle"
            :cardColor="cardUtils.cardColor(cardInfo)"
            :title="cardTitle"
            :text="cardUtils.sumImpact(cardInfo)"
            @click="getNEData(cardTitle)"
          />
        </div>
        <div class="elements-list">
          <DataCard
            v-for="(cardInfo, cardTitle) in totalInfoEl"
            :key="cardTitle"
            :cardColor="cardUtils.cardColor(cardInfo)"
            :title="cardTitle"
            :text="cardUtils.sumImpact(cardInfo)"
            @click="getNEData(cardTitle)"
          />
        </div>
      </div>
      <!--      <div>-->
      <!--        <button class="hoba-button" @click="hoba">Хоба</button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import DataCard from "@/components/DataCard";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import cardUtils from "@/store/utils/cardUtils";
import { useRouter } from "vue-router/dist/vue-router";

const store = useStore();
const router = useRouter();
const totalInfo = computed(() => store.getters.totalInfo);
// const visibleSidebar = computed(() => store.getters.sidebarVisible);
store.commit("SET_SIDEBAR_VISIBLE", false);
store.commit("SET_CURRENT_EL", null);

const getNEData = async (ne) => {
  await store.dispatch("getNeInfo", ne);
  await router.push({ path: `/ne/${ne}` });
};

const totalInfoCores = computed(() => {
  let cores = {};
  for (let title in totalInfo.value) {
    // console.log(title);
    if (title.includes("CORE")) {
      cores[title] = [];
      cores[title] = totalInfo.value[title];
    }
  }
  // console.log("cores", cores);
  return cores;
});

const totalInfoEl = computed(() => {
  let elements = {};
  for (let title in totalInfo.value) {
    // console.log(title);
    if (!title.includes("CORE")) {
      elements[title] = [];
      elements[title] = totalInfo.value[title];
    }
  }
  // console.log("cores", elements);
  return elements;
});

// const hoba = () => {
//   store.commit("SET_SIDEBAR_VISIBLE", !visibleSidebar.value);
// };
onMounted(async () => {
  await store.dispatch("getTotalInfo");
});
</script>

<style scoped>
.home {
  /*@apply flex flex-wrap m-auto;*/
}

.home-wrapper {
  @apply flex m-auto flex-col items-center gap-7 w-3/5;
}

.home-content {
  /*@apply flex flex-1 flex-wrap gap-5;*/
  @apply grid gap-5;
}

.cores-list {
  @apply flex flex-1 flex-wrap gap-5;
}

.elements-list {
  @apply flex flex-1 flex-wrap gap-5;
}

.hoba-button {
  @apply p-5 bg-blue-900 text-slate-100 rounded-full animate-bounce;
}
</style>
