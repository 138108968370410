import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NEView from "../views/NEView.vue";
import IncidentView from "../views/IncidentView.vue";
import PermissionDenied from "../views/PermissionDenied.vue";
import store from "@/store";
import { VUE_APP_HOST } from "@/store/utils/constants";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/ne/:ne",
    name: "ne-view",
    component: NEView,
  },
  {
    path: "/ne/:ne/incident",
    name: "incident-view",
    component: IncidentView,
  },
  {
    path: "/permission_denied",
    name: "permission-denied",
    component: PermissionDenied,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "permission-denied") {
    next();
  } else if (await store.dispatch("checkSession")) {
    if (from.name === undefined && to.params.ne) {
      if (to.name === "incident-view") {
        next(`ne/${to.params.ne}`);
      } else {
        store.dispatch("getNeInfo", to.params.ne).then(() => next());
      }
    } else next();
  } else {
    // eslint-disable-next-line
    // const url = await store.dispatch("getWebSsoUrl");
    // if (url) window.location.href = url;
    window.location.href = `${VUE_APP_HOST}/auth/authorize?goto=${VUE_APP_HOST}`;
  }
  // console.log()
  // next();
});

export default router;
