<template>
  <div class="ne-view">
    <div class="ne-wrapper">
      <div class="ne-content" v-if="neInfo">
        <DataCard
          v-for="(cardInfo, cardTitle) in neInfo"
          :key="cardTitle"
          :cardColor="setColor(cardTitle, cardInfo.impact)"
          :title="cardTitle"
          :text="cardUtils.sumImpact(cardInfo.impact)"
          @click="getTTData(cardTitle, cardInfo.tt_list)"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import DataCard from "@/components/DataCard";
import { useStore } from "vuex";
import { computed, onBeforeUnmount } from "vue";
import cardUtils from "@/store/utils/cardUtils";
// import { useRouter } from "vue-router/dist/vue-router";

const store = useStore();
// const router = useRouter();
const neInfo = computed(() => store.getters.neInfo);
const currentEl = computed(() => store.getters.currentEl);

const getTTData = (neEl, ttList) => {
  // console.log(neEl);
  let current = {
    element: neEl,
    ttList: ttList,
  };
  store.commit("SET_CURRENT_EL", current);
  store.commit("SET_SIDEBAR_VISIBLE", true);
};

const setColor = (title, impact) => {
  if (currentEl.value && currentEl.value.element === title) {
    return cardUtils.cardActiveColor(impact);
  } else return cardUtils.cardColor(impact);
};

onBeforeUnmount(() => {
  // store.commit("SET_SIDEBAR_VISIBLE", false);
  // store.commit("SET_CURRENT_EL", null);
});
</script>

<style scoped>
.ne-wrapper {
  @apply flex m-auto flex-col items-center;
}

.ne-content {
  @apply flex flex-1 flex-col flex-wrap gap-5;
}
</style>
