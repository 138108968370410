import { createStore } from "vuex";
import axios from "axios";
import authModule from "@/store/auth";
import { VUE_APP_HOST } from "@/store/utils/constants.js";

export default createStore({
  state: {
    totalInfo: null,
    neInfo: null,
    ttInfo: null,
    currentEl: null,
    currentTT: null,
    sidebarVisible: false,
    dialogVisible: false,
    organization: null,
  },
  getters: {
    totalInfo: (state) => {
      return state.totalInfo;
    },
    neInfo: (state) => {
      return state.neInfo;
    },
    ttInfo: (state) => {
      return state.ttInfo;
    },
    currentEl: (state) => {
      return state.currentEl;
    },
    currentTT: (state) => {
      return state.currentTT;
    },
    sidebarVisible: (state) => {
      return state.sidebarVisible;
    },
    dialogVisible: (state) => {
      return state.dialogVisible;
    },
    organization: (state) => {
      return state.organization;
    },
  },
  mutations: {
    SET_TOTAL_INFO: (state, info) => {
      state.totalInfo = info;
    },
    SET_NE_INFO: (state, info) => {
      state.neInfo = info;
    },
    SET_TT_INFO: (state, info) => {
      state.ttInfo = info;
    },
    SET_CURRENT_EL: (state, el) => {
      state.currentEl = el;
    },
    SET_CURRENT_TT: (state, tt) => {
      state.currentTT = tt;
    },
    SET_SIDEBAR_VISIBLE: (state, visible) => {
      state.sidebarVisible = visible;
    },
    SET_DIALOG_VISIBLE: (state, visible) => {
      // console.log("я тут SET_DIALOG_VISIBLE");
      state.dialogVisible = visible;
    },
    SET_ORGANIZATION: (state, organization) => {
      state.organization = organization;
    },
  },
  actions: {
    getTotalInfo: async ({ commit }) => {
      await axios
        .get(`${VUE_APP_HOST}/data/total_info`, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log(response.data);
          commit("SET_TOTAL_INFO", response.data);
        });
      // .catch((error) => {
      //   console.log("error ->", error);
      // });
    },
    getNeInfo: async ({ commit }, ne) => {
      await axios
        .get(`${VUE_APP_HOST}/data/ne_info?ne=${ne}`, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log("get ne info", response.data);
          commit("SET_NE_INFO", response.data);
        });
      // .catch((error) => {
      //   console.log("error ->", error);
      // });
    },
    getTTInfo: async ({ commit }, requestId) => {
      await axios
        .get(`${VUE_APP_HOST}/data/tt_info?tt=${requestId}`, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log("get tt info", response.data);
          commit("SET_TT_INFO", response.data);
          commit("SET_CURRENT_TT", requestId);
        });
      // .catch((error) => {
      //   console.log("error ->", error);
      // });
    },
    getOrganization: async ({ commit }) => {
      await axios
        .get(`${VUE_APP_HOST}/data/organization`, {
          withCredentials: true,
        })
        .then((response) => {
          // console.log("get tt info", response.data);
          commit("SET_ORGANIZATION", response.data);
        });
    },
  },
  modules: {
    authModule,
  },
});
