class cardUtils {
  static sumImpact = (impactArr) => {
    return `Количество Инцидентов - ${impactArr[0] + impactArr[1]}`;
  };

  static cardColor = (impactArr) => {
    if (impactArr[0] > 0) return "bg-red-400 dark:bg-red-600/70";
    if (impactArr[1] > 0)
      return "bg-yellow-600/60 dark:bg-amber-200/70 dark:text-slate-600";
    if (impactArr[0] === 0 && impactArr[1] === 0)
      return "bg-green-600/60 dark:bg-green-600/70";
  };
  static cardActiveColor = (impactArr) => {
    if (impactArr[0] > 0)
      return "bg-red-500 dark:bg-red-600/100 -translate-y-1 scale-[1.065]";
    if (impactArr[1] > 0)
      return "bg-yellow-600/100 dark:bg-amber-300/70 dark:text-slate-600 -translate-y-1 scale-[1.065]";
    if (impactArr[0] === 0 && impactArr[1] === 0)
      return "bg-green-600/100 dark:bg-green-500/80 -translate-y-1 scale-[1.065]";
  };
}

export default cardUtils;
