<template>
  <div class="dialog-wrapper">
    <div @click="cancel" class="overlay"></div>
    <div>
      <div class="dialog-content">
        <h1 class="dialog-title">Выход</h1>
        <p class="dialog-text">Вы действительно хотите выйти?</p>
        <div class="dialog-actions">
          <div>
            <button @click="cancel" class="btn cancel-btn">Отмена</button>
          </div>
          <div>
            <button @click="exit" class="btn exit-btn">Выйти</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { VUE_APP_LOGOUT, VUE_APP_HOST } from "@/store/utils/constants";

const store = useStore();

const cancel = () => {
  store.commit("SET_DIALOG_VISIBLE", false);
};

const exit = async () => {
  await store.dispatch("closeSession");
  window.location.href = `${VUE_APP_LOGOUT}?goto=${VUE_APP_HOST}`;
};
</script>

<style scoped>
.dialog-wrapper {
  @apply h-full w-full;
}
.overlay {
  @apply absolute left-0 top-0 w-full h-full bg-black opacity-60 z-20 flex justify-center;
}

.dialog-content {
  @apply relative flex flex-col max-w-4xl bg-slate-300 dark:bg-slate-700 rounded z-30 p-5 m-10 gap-10;
}
.dialog-title {
  @apply text-2xl;
}
.dialog-text {
  @apply text-center text-lg;
}
.dialog-actions {
  @apply flex justify-end gap-2;
}

.btn {
  @apply p-1 border-none rounded w-24 text-lg uppercase tracking-wider;
}

.cancel-btn {
  @apply hover:bg-slate-400 hover:dark:bg-slate-600 text-slate-800 dark:text-slate-300 hover:text-slate-800 hover:dark:text-slate-300;
}

.exit-btn {
  @apply bg-slate-500 dark:bg-slate-800 hover:bg-slate-600 hover:dark:bg-slate-900 text-slate-300 dark:text-slate-300;
}
</style>
